import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';
import axios from 'axios';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { StylesProvider, Typography } from '@material-ui/core';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';

const jsonConfig = require('../../Config.json');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    /**/ container: {
      //backgroundColor: theme.palette.background.default,
      //custom blueleap theme
      //'background-color': 'rgb(242, 247, 255)!important',
      //'background-color': '#4CAF50',
      'box-shadow': 'none',
    },
    toolbar: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
      color: '#24527e',
      borderBottom: '1px solid #24527e',
    },
    greentextField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
      color: '#24527e',
    },
    selectColor: {
      margin: '1em',
      color: '#24527e',
      padding: '10px',
      borderRadius: '4px',
    },
    flipCamera: {
      fill: '#24527e',
    },
  })
);

export default function MenuBar() {
  let dropDownVal: string = localStorage.getItem('colorCode') || 'Blue';

  if (typeof localStorage.getItem('colorCode') === 'string') {
  }
  if (
    localStorage.getItem('colorCode') === 'null' ||
    localStorage.getItem('colorCode') === 'undefined' ||
    localStorage.getItem('colorCode') === '' ||
    localStorage.getItem('colorCode') === null ||
    localStorage.getItem('colorCode') === undefined
  ) {
    jsonConfig.color = 'Blue';
  } else {
    jsonConfig.color = localStorage.getItem('colorCode');
    //dropDownVal=localStorage.getItem("colorCode");
  }

  const classes = useStyles();
  const { URLRoomName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [theme, setTheme] = useState<string>('');

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const themeChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTheme(event.target.value);
    localStorage.setItem('colorCode', event.target.value);
    window.location.reload();
    //jsonConfig.color=event.target.value
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    /**/ event.preventDefault();
    axios.get('https://jsonplaceholder.typicode.com/todos/1').then(
      response => {
        console.log('RESP', response);
      },
      error => {
        console.log(error);
      }
    );
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    getToken(name, roomName).then(token => connect(token));
    //alert("HEELOOO");
  };

  return (
    <AppBar
      className={classes.container}
      position="static"
      style={{ backgroundColor: jsonConfig.color === 'Green' ? '#4CAF50' : 'rgb(242, 247, 255)' }}
    >
      <Toolbar className={classes.toolbar}>
        {jsonConfig.color === 'Green' ? (
          <img
            src="https://blueleap-profile-pictures-dev.s3.amazonaws.com/blLogo1_white.svg"
            alt="logo"
            className="logoImg"
          />
        ) : (
          <img
            src="https://blueleap-profile-pictures-dev.s3.amazonaws.com/blLogo1.svg"
            alt="logo"
            className="logoImg"
          />
        )}
        {roomState === 'disconnected' ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
              <TextField
                id="menu-name"
                placeholder="Name"
                value={name}
                onChange={handleNameChange}
                margin="dense"
                InputProps={{
                  className: jsonConfig.color === 'Green' ? classes.greentextField : classes.textField,
                }}
              />
            ) : (
              <Typography className={classes.displayName} variant="body1">
                {user.displayName}
              </Typography>
            )}
            <TextField
              id="menu-room"
              placeholder="Room"
              value={roomName}
              onChange={handleRoomNameChange}
              margin="dense"
              InputProps={{
                className: jsonConfig.color === 'Green' ? classes.greentextField : classes.textField,
              }}
            />
            <Button
              className={classes.joinButton}
              type="submit"
              color="primary"
              variant="contained"
              style={{
                color: jsonConfig.color === 'Green' ? '#4caf50' : '#24527e',
                border: jsonConfig.color === 'Green' ? '2px solid #fff' : '2px solid #24527e',
              }}
              disabled={isAcquiringLocalTracks || isConnecting || !name || !roomName || isFetching}
            >
              Join Room
            </Button>

            <select
              className={classes.selectColor}
              value={dropDownVal}
              onChange={themeChangeHandler}
              style={{
                color: jsonConfig.color === 'Green' ? '#4caf50' : '#24527e',
                border: jsonConfig.color === 'Green' ? '2px solid #fff' : '2px solid #24527e',
              }}
            >
              <option value="">Select Color</option>
              <option value="Blue">Blue</option>
              <option value="Green">Green</option>
            </select>
            {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
          </form>
        ) : (
          <h3 style={{ color: jsonConfig.color === 'Green' ? '#fff' : '#24527e' }}>{roomName}</h3>
        )}

        <div className={classes.rightButtonContainer}>
          <FlipCameraButton />
          <LocalAudioLevelIndicator />
          <ToggleFullscreenButton />
          <Menu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
