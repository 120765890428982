/*var x=document.getElementsByClassName("MuiFab-label");
console.log(x);
if(localStorage.getItem('colorCode')==='Blue')
{
    x.style.color = "red";
}
else{
    x.style.color = "blue";
}
*/
